









































import db from "@/db";
import ItemCardWrapper from "@/components/UserWishlist/ItemCardWrapper.vue";
import wishlistItemsService from "@/services/wishlistItemsService";
import {Component, Prop, Vue} from "vue-property-decorator";

type WishListItemDto = any & {
  id: string | null;
  archived: boolean
};

@Component<UserWishlist>({
  components: {ItemCardWrapper},
  watch: {
    userId(userId) {
      this.init(userId);
    },
    editable() {
      this.init(this.userId!);
    },
  },
  beforeMount() {
    this.init(this.userId!);
  }
})
export default class UserWishlist extends Vue {
  @Prop()
  userId!: string | null
  @Prop()
  editable!: boolean


  items: WishListItemDto[] = []
  dataReady: boolean = false
  unsubscribe: any = function () {/* Stub */}


  removeWishlistItem(id: string) {
    if (this.editable) {
      db.wishlistItems.doc(id).delete();
    }
  }

  archiveWishlistItem(item: WishListItemDto) {
    if (this.editable) {
      item.archived = true
      wishlistItemsService.edit(item.id, item);
    }
  }

  editItem(item: WishListItemDto) {
    wishlistItemsService.edit(item.id, item)
  }

  private itemsComparator = (a: WishListItemDto, b: WishListItemDto) => {
    // Firstly push down all archived items
    // then compare by date desc
    let archivedSorting = (+!!a.archived) - (+!!b.archived)
    if (archivedSorting != 0) {
      return archivedSorting
    }
    return (b.createdAt?.seconds || 0) - (a.createdAt?.seconds || 0)
  };

  init(userId: string) {
    this.unsubscribe();

    if (userId != null) {
      let targetCollectionSelection = db.wishlistItems.where(
          "uid",
          "==",
          userId
      );

      if (!this.editable) {
        targetCollectionSelection = targetCollectionSelection.where(
            "private",
            "==",
            false
        );
      }

      this.unsubscribe = targetCollectionSelection.onSnapshot(
          (querySnapshot) => {
            this.items = querySnapshot.docs.map((it) => {
              return {
                ...it.data(),
                id: it.id,
              } as WishListItemDto;
            })
            .sort(this.itemsComparator);
            this.dataReady = true;
          }
      );
    } else {
      this.items = [];
      this.dataReady = true;
    }
  }

}
