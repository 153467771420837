





































































import TagBadge from "@/components/TagBadge.vue";
import ItemCardOpenButton from "@/components/UserWishlist/ItemCardOpenButton.vue";
import dateUtils from "@/js/utils/DateUtils";
import dayjs from "dayjs";
import {Component, Prop, Vue} from "vue-property-decorator";
import ProgressBar from "@/components/ProgressBar.vue";

@Component<ItemCard>({
  name: "ItemCard",
  components: {ProgressBar, TagBadge, ItemCardOpenButton}
})
export default class ItemCard extends Vue {
  @Prop()
  item: any | null
  @Prop()
  editable!: boolean

  tryRemove() {
    if (confirm("Удалить желание?")) {
      this.$emit("remove");
    }
  }

  get createdAtStr() {
    let date = this.item.createdAt ? dayjs(new Date(this.item.createdAt.seconds * 1000)) : null;
    if (!date) return "";
    return dateUtils.displayStringOf(date)
  }

}
