var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-navbar',{style:({background: '#ffffff'}),attrs:{"toggleable":"lg","type":"light","variant":"custom","sticky":""}},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-navbar-brand',{attrs:{"href":href}},[_c('b-avatar',{attrs:{"square":"","variant":"transparent","src":_vm.logoUrl,"size":"1.5em"}}),_vm._v(" WishList ")],1)]}}])}),_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}}),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[_c('b-navbar-nav',[(_vm.user)?_c('router-link',{attrs:{"to":'/user/' + _vm.user.uid,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-nav-item',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"house-door"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Мои желания")])],1)]}}],null,false,205004600)}):_vm._e(),(_vm.user)?_c('router-link',{attrs:{"to":"/fav/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-nav-item',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"star"}}),_c('span',{staticClass:"ml-1"},[_vm._v("Избранное")])],1)]}}],null,false,1465522478)}):_vm._e()],1),_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.user)?_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" "+_vm._s(_vm.profile.displayName || _vm.user.displayName || _vm.user.email)+" "),_c('b-avatar',{staticClass:"ml-1",attrs:{"variant":"light","src":_vm.profile.photoURL || _vm.user.photoURL}})]},proxy:true}],null,false,2308727954)},[_c('b-dropdown-item',{attrs:{"disabled":"","href":"#"}}),_c('router-link',{attrs:{"to":"/profile/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
return [_c('b-dropdown-item',{attrs:{"href":href}},[_c('b-icon',{attrs:{"icon":"person"}}),_vm._v(" Профиль ")],1)]}}],null,false,1614293282)}),_c('b-dropdown-item',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$emit('signOut')}}},[_c('b-icon',{attrs:{"icon":"box-arrow-right"}}),_vm._v(" Выйти ")],1)],1):_vm._e(),(!_vm.user)?_c('b-nav-item',{attrs:{"right":""},on:{"click":function($event){return _vm.$emit('signIn')}}},[_c('b-icon',{attrs:{"icon":"google"}}),_vm._v(" Войти")],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }