

























import {Component, Prop, Vue} from "vue-property-decorator";

@Component<ItemCardOpenButton>({
  name: "ItemCardOpenButton"
})
export default class ItemCardOpenButton extends Vue {
  @Prop()
  name!: string

  @Prop()
  link!: string | null

  get searchLink() {
    return "https://www.google.com/search?q=" + this.name; // TODO sanitize
  }
}
