


































































































import wishlistItemsService from "@/services/wishlistItemsService"
import TagBadge from "@/components/TagBadge.vue"
import profileService from "@/services/profileService"
import dateUtils from "@/js/utils/DateUtils"
import { Component, Prop, Vue } from "vue-property-decorator"
import UserProfileEntity from "@/db/model/UserProfileEntity"
import PseudoImage from "@/components/PseudoImage.vue"
import ProgressBar from "@/components/ProgressBar.vue"

@Component<FeedItem>({
  name: "FeedItem",
  components: { ProgressBar, PseudoImage, TagBadge },
  async beforeMount(): Promise<void> {
    this.profile = (await profileService.getUserProfileOrNull(
      ((this.item as any)?.stored?.uid as string) || ""
    )) as any
  },
})
export default class FeedItem extends Vue {
  @Prop()
  item: any | null

  profile: UserProfileEntity | null = null
  isAdded: boolean = false

  get user(): any {
    return this.$store.state.user
  }

  get isMyItem(): Boolean {
    return this.item.stored.uid === this.user.uid
  }

  get createdAtStr(): String {
    return dateUtils.displayStringOf(this.item.createdAt)
  }

  addToMyList(item: any): void {
    if (this.isAdded) {
      return
    }

    wishlistItemsService.create(item.stored)
    this.isAdded = true
  }
}
